<template>
    <Main>
        <Header></Header>
        <div class="line"></div>
        <Block>
            <div class="success-main">
                <img class="success-icon" :src="successIcon" />
                <div class="explain" v-if="lang == 'zh'">
                    <span>{{$t('registerSuccess.explain1')}}</span>
                    <span class="red">{{$t('registerSuccess.explain2')}}</span>
                    <span>{{$t('registerSuccess.explain3')}}</span>
                </div>
                <div class="explain" v-if="lang == 'en'">
                    <span>{{$t('registerSuccess.explain1')}}</span>
                </div>
                <div class="button" @click="$router.push({path: '/login'})">{{$t('registerSuccess.button')}}</div>
            </div>
        </Block>
    </Main>
</template>

<script>
import Main from '@/components/layout1/layout1-main.vue';
import Header from '@/components/layout1/layout1-header.vue';
import Block from '@/components/layout1/layout1-block.vue';
import successIcon from '@/assets/img/success.png';
import {getLanguage} from "@/lang/index.js";
export default {
    name: 'register',
    components: {
        Main,
        Header,
        Block,
    },
    data() {
        return {
            lang: getLanguage(),
            successIcon,
        }
    },
    computed: {
        
    },
    methods: {
        
    },
    watch: {

    }
}
</script>
<style lang="less" scoped>
.line{
    width:100%;
    border-top: 2px solid #3053A3;
}
.success-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    .success-icon{
        margin-top: 120px;
        width: 60px;
        height: 60px;
    }
    .explain{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        margin-top: 30px;
        .red{
            color: #3053A3;
        }
    }
    .button{
        cursor: pointer;

        margin-top: 60px;
        min-width: 180px;
        height: 40px;
        border-radius: 4px;

        font-size: 14px;
        font-weight: 400;

        border: 1px solid #3053A3;
        color:#3053A3;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
